const MenuLists = [
  {
    type: "text",
    name: "ホーム",
    url: "/",
    active: false,
  },
  {
    type: "text",
    name: "本事業について",
    url: "/about",
    active: false,
  },
  {
    type: "line",
  },
  {
    type: "text",
    name: "2023年度 技術シーズ一覧",
    url: "/project-all-2023",
    active: false,
  },
  {
    type: "text",
    name: "2022年度 技術シーズ一覧",
    url: "/project-all-2022",
    active: false,
  },
  {
    type: "text",
    name: "2021年度 技術シーズ一覧",
    url: "/project-all-2021",
    active: false,
  },
  {
    type: "text",
    name: "2020年度 技術シーズ一覧",
    url: "/project-all-2020",
    active: false,
  },
  {
    type: "line",
  },
  {
    type: "text",
    name: "DEMO DAY 2024",
    url: "/demoday2024",
    active: false,
  },
  {
    type: "text",
    name: "DEMO DAY 2023",
    url: "/demoday2023",
    active: false,
  },
  {
    type: "text",
    name: "DEMO DAY 2022",
    url: "/demoday2022",
    active: false,
  },
  {
    type: "text",
    name: "DEMO DAY 2021",
    url: "https://www.youtube.com/watch?v=8u20JpatnLc&t=162s&ab_channel=%E7%A5%9E%E6%88%B8%E5%A4%A7%E5%AD%A6%E3%83%BB%E5%A4%A7%E9%98%AA%E5%B7%A5%E6%A5%AD%E5%A4%A7%E5%AD%A6JSTSCORE%E4%BA%8B%E6%A5%AD",
    active: false,
  },
  {
    type: "line",
  },
  {
    type: "text",
    name: "神戸大学",
    url: "/university-1",
    active: false,
  },
  {
    type: "text",
    name: "大阪工業大学",
    url: "/university-2",
    active: false,
  },
];
export default MenuLists;
